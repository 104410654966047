import {useContext} from "react"
import moment from "moment-timezone"
import {CustomProvider} from "rsuite"
import en from "rsuite/esm/locales/default"
import deDE from "rsuite/esm/locales/de_DE"
import nlNL from "rsuite/esm/locales/nl_NL"
import AureliaContext from "../utilities/aurelia-context"

/*
const date = {
    sunday: 'So',
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    ok: 'OK',
    today: 'heute',
    yesterday: 'gestern',
    formattedMonthPattern: 'MMM YYYY',
    May: 'Mai',
    Dec: 'Dez'
}
const deDE = {
    Table: {
        loading: "Laden …"
    },
    Picker: {
        placeholder: "Auswählen"
    },
    DatePicker: {
        ...date,
        formattedDayPattern: 'DD. MMMM YYYY',
    },
    DateRangePicker: {
        ...date,
        last7Days: 'Letzte Woche',
        formattedDayPattern: "d.M.YYYY"
    }
}
*/
const formatDate = (data, formatStr) => moment(data).format(formatStr === 'dd.MM.yyyy' ? 'DD.MM.YYYY' : formatStr)

export default function RSuiteLocale({children}) {
    const {userClient} = useContext(AureliaContext)
    let locale

    switch (userClient.getUser()?.settings?.userSettings?.locale) {
        case "de":
        case "de_DE":
        case "de_AT":
        case "de_CH":
            deDE.DatePicker = {formattedMonthPattern: 'MMM YYYY',ok: 'OK', ...deDE.DatePicker.date }
            deDE.DateRangePicker = {formattedMonthPattern: 'MMM YYYY',ok: 'OK', ...deDE.DatePicker.date }
            locale = deDE
            break

        case "nl":
        case "nl_NL":
        case "nl_BE":
            locale = nlNL
            break

        default:
            locale = en
    }

    return (
        <CustomProvider locale={locale} formatDate={formatDate}>
            {children}
        </CustomProvider>
    )
}
